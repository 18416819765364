import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import BeatLoader from "react-spinners/BeatLoader"

const IndexPage = ({ location }) => {
  const lang = "en"
  const pageSlug = "index"

  // Function for redirect
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "en"
    }

    const browserLang =
      navigator && navigator.language && navigator.language.split("-")[0]
    if (!browserLang) return "en"

    switch (browserLang) {
      case "ja":
        return "jp"
      default:
        return "en"
    }
  }

  useEffect(() => {
    const urlLang = getRedirectLanguage()
    navigate(`/${urlLang}/`)
  }, [])

  const loading = useState(true)
  const loadingStyle = {
    display: "block",
    textAlign: "center",
    padding: "1rem",
    animationDuration: "0.5s",
  }

  return (
    <>
      <SEO lang={lang} pageSlug={pageSlug} location={location} />
      <div className="fadein" style={loadingStyle}>
        <BeatLoader size={10} color={"#313131"} loading={loading} />
      </div>
    </>
  )
}

export default IndexPage
